module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Drivehub","short_name":"Drivehub","start_url":"/","background_color":"#0053ba","theme_color":"#0078ff","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"drivehub-homepage-cms","short_name":"dh-cms","start_url":"/","background_color":"#0052AE","theme_color":"#0052AE","display":"minimal-ui","icon":"src/images/favicon-96x96.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
