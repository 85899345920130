// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-content-index-js": () => import("../src/templates/content/index.js" /* webpackChunkName: "component---src-templates-content-index-js" */),
  "component---src-templates-city-js": () => import("../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-promotion-index-js": () => import("../src/templates/promotion/index.js" /* webpackChunkName: "component---src-templates-promotion-index-js" */),
  "component---src-templates-interbrand-index-js": () => import("../src/templates/interbrand/index.js" /* webpackChunkName: "component---src-templates-interbrand-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-car-with-driver-index-js": () => import("../src/pages/en/car-with-driver/index.js" /* webpackChunkName: "component---src-pages-en-car-with-driver-index-js" */),
  "component---src-pages-en-help-center-js": () => import("../src/pages/en/help-center.js" /* webpackChunkName: "component---src-pages-en-help-center-js" */),
  "component---src-pages-en-index-js": () => import("../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-long-term-rental-index-js": () => import("../src/pages/en/long-term-rental/index.js" /* webpackChunkName: "component---src-pages-en-long-term-rental-index-js" */),
  "component---src-pages-en-long-term-rental-successfully-index-js": () => import("../src/pages/en/long-term-rental/successfully/index.js" /* webpackChunkName: "component---src-pages-en-long-term-rental-successfully-index-js" */),
  "component---src-pages-en-promotions-lucky-draw-js": () => import("../src/pages/en/promotions/lucky-draw.js" /* webpackChunkName: "component---src-pages-en-promotions-lucky-draw-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-th-car-with-driver-index-js": () => import("../src/pages/th/car-with-driver/index.js" /* webpackChunkName: "component---src-pages-th-car-with-driver-index-js" */),
  "component---src-pages-th-help-center-js": () => import("../src/pages/th/help-center.js" /* webpackChunkName: "component---src-pages-th-help-center-js" */),
  "component---src-pages-th-index-js": () => import("../src/pages/th/index.js" /* webpackChunkName: "component---src-pages-th-index-js" */),
  "component---src-pages-th-long-term-rental-index-js": () => import("../src/pages/th/long-term-rental/index.js" /* webpackChunkName: "component---src-pages-th-long-term-rental-index-js" */),
  "component---src-pages-th-long-term-rental-successfully-index-js": () => import("../src/pages/th/long-term-rental/successfully/index.js" /* webpackChunkName: "component---src-pages-th-long-term-rental-successfully-index-js" */),
  "component---src-pages-th-promotions-lucky-draw-js": () => import("../src/pages/th/promotions/lucky-draw.js" /* webpackChunkName: "component---src-pages-th-promotions-lucky-draw-js" */)
}

